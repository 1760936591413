import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, useMediaQuery, Drawer, Button, Stack, IconButton } from '@mui/material';
import WholeshopLogo from '../../icons/WholeshopLogo.jsx';
import WholeshopLogoWhite from '../../icons/WholeshopLogoWhite.jsx';
import WholeshopLogoIcon from '../../icons/WholeshopLogoIcon.jsx';
import WholeshopLogoIconWhite from '../../icons/WholeshopLogoIconWhite.jsx';
import ListIcon from '../../icons/ListIcon.jsx';
import theme, { Midnight, SoftBG, Straw, White } from '../../theme.js';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const StickyHeader = () => {
    const [headerColor, setHeaderColor] = useState('transparent');
    const [primaryButtonColor, setPrimaryButtonColor] = useState(White);
    const [primaryButtonTitleColor, setPrimaryButtonTitleColor] = useState(Midnight);
    const [menuButtonColor, setMenuButtonColor] = useState(White);
    const [secondaryButtonTitleColor, setSecondaryButtonTitleColor] = useState(White);
    const [isScrolled, setIsScrolled] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const isSmBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    const navigate = useNavigate();
    const handleLoginSelect = () => {
        navigate('/login');
    };

    const handleSignUpSelect = () => {
        navigate('/signUp');
    };

    const toggleDrawer = () => {
        setShowDrawer(!showDrawer);
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            if (scrollY > 200) {
                setHeaderColor(Straw);
                setPrimaryButtonColor(Midnight);
                setPrimaryButtonTitleColor(White);
                setSecondaryButtonTitleColor(Midnight);
                setMenuButtonColor(Midnight);
                setIsScrolled(true);
            } else {
                setHeaderColor('transparent');
                setPrimaryButtonColor(White);
                setPrimaryButtonTitleColor(Midnight);
                setSecondaryButtonTitleColor(White);
                setMenuButtonColor(White);
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <AppBar
                position="fixed"
                elevation={0}
                style={{ backgroundColor: headerColor, height: '95px', transition: 'background-color 0.5s' }}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '100%',
                        minHeight: '95px',
                        mx: { xs: 1, sm: 5 },
                    }}>
                    {isScrolled ? (
                        isSmBreakpoint ? (
                            <WholeshopLogoIcon sx={{ width: 32, height: 32 }} />
                        ) : (
                            <WholeshopLogo sx={{ width: 226, height: 35 }} />
                        )
                    ) : isSmBreakpoint ? (
                        <WholeshopLogoIconWhite sx={{ width: 32, height: 32 }} />
                    ) : (
                        <WholeshopLogoWhite sx={{ width: 226, height: 35 }} />
                    )}

                    <Stack direction="row" gap={2} sx={{ alignItems: 'center' }}>
                        {!isSmBreakpoint ? (
                            <Button onClick={handleLoginSelect} sx={{ color: secondaryButtonTitleColor }}>
                                {t('StickyHeader.loginButton')}
                            </Button>
                        ) : null}
                        <Button
                            onClick={handleSignUpSelect}
                            variant="contained"
                            sx={{ backgroundColor: primaryButtonColor, color: primaryButtonTitleColor }}>
                            {t('StickyHeader.joinButton')}
                        </Button>
                        {isSmBreakpoint ? (
                            <IconButton onClick={toggleDrawer}>
                                <ListIcon sx={{ color: menuButtonColor }} />
                            </IconButton>
                        ) : null}
                    </Stack>
                </Toolbar>
            </AppBar>

            {/* Drawer Component */}
            <Drawer
                anchor="right"
                open={showDrawer}
                onClose={toggleDrawer}
                PaperProps={{
                    sx: {
                        bgcolor: SoftBG,
                        border: 'none',
                        borderRadius: '24px 0px 0px 24px',
                        px: { sm: 2 },
                        boxSizing: 'border-box',
                    },
                }}
                sx={{
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 300 },
                }}>
                <Stack gap={2} sx={{ alignItems: 'flex-start', mt: 4, ml: 3 }}>
                    <Button variant="contained" onClick={handleSignUpSelect}>
                        {t('StickyHeader.joinButton')}
                    </Button>
                    <Button onClick={handleLoginSelect}>{t('StickyHeader.loginButton')}</Button>
                </Stack>
            </Drawer>
        </>
    );
};

export default StickyHeader;
