import {
    Box,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    Paper,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import BusinessLogo from '../../../../components/BusinessLogo';
import PlusCircleIcon from '../../../../icons/PlusCircleIcon';
import WholeshopLogoIcon from '../../../../icons/WholeshopLogoIcon.jsx';

const drawerWidth = 80;

export default function ConnectionsDrawer(props) {
    const { activeBusiness } = props;
    const rootData = useRouteLoaderData('root');
    const navigate = useNavigate();
    const [showDrawer, setShowDrawer] = React.useState(false);

    const theme = useTheme();
    const isSmBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));

    const handleButtonClick = (business) => () => {
        setShowDrawer(false);
        navigate(`/retail/connections/${business?.id}/products`);
    };
    const handleAddButtonClick = () => {
        setShowDrawer(false);
        navigate('/retail/settings/invite');
    };
    const isActiveBusiness = (id) => activeBusiness.id === id;

    return (
        <>
            <Box sx={{ display: showDrawer ? 'none' : undefined }}>
                <Paper
                    elevation={3}
                    sx={{
                        borderRadius: 2,
                        position: 'fixed',
                        top: 24,
                        left: 24,
                        zIndex: (theme) => theme.zIndex.drawer,
                    }}>
                    <IconButton onClick={() => setShowDrawer(!showDrawer)} sx={{ p: 0 }}>
                        <BusinessLogo
                            business={activeBusiness ?? {}}
                            sx={{ height: 48, width: 48 }}
                            CardMediaProps={{ sx: { borderRadius: 1 } }}
                        />
                    </IconButton>
                </Paper>
            </Box>
            <Drawer
                className="ConnectionsDrawer"
                variant={isSmBreakpoint ? 'permanent' : 'temporary'}
                open={isSmBreakpoint || showDrawer}
                onClose={() => setShowDrawer(false)}
                sx={{ width: drawerWidth }}
                PaperProps={{ sx: { bgcolor: 'common.lightGrey' } }}>
                <List sx={{ pt: 3 }}>
                    {rootData?.business?.connectedBusiness?.map((business) => (
                        <ListItem key={business.id} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                onClick={handleButtonClick(business)}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: 'initial',
                                    px: '19px',
                                }}>
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        justifyContent: 'center',
                                    }}>
                                    <BusinessLogo
                                        business={business}
                                        sx={{ height: 42, width: 42 }}
                                        CardMediaProps={{
                                            sx: {
                                                borderRadius: '10px',
                                                objectFit: 'cover',
                                                border: (theme) =>
                                                    isActiveBusiness(business.id)
                                                        ? `2px solid ${theme.palette.common.Teal}`
                                                        : `1px solid ${theme.palette.common.Fog}`,
                                                boxShadow: () =>
                                                    isActiveBusiness(business.id)
                                                        ? '1px 1px 20px 0px rgba(255, 255, 255, 0.35)'
                                                        : undefined,
                                            },
                                        }}
                                    />
                                </ListItemIcon>
                            </ListItemButton>
                        </ListItem>
                    ))}
                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            onClick={handleAddButtonClick}
                            sx={{
                                minHeight: 48,
                                justifyContent: 'center',
                                px: '19px',
                            }}>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    bgcolor: 'common.Tide',
                                    p: 0.5,
                                    borderRadius: '10px',
                                    color: 'common.white',
                                    height: 42,
                                    width: 42,
                                }}>
                                <PlusCircleIcon sx={{ fontSize: '32px' }} />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                </List>
                <List sx={{ flex: '1 0 auto', display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: 'center',
                                px: 1,
                            }}>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    justifyContent: 'center',
                                }}>
                                <WholeshopLogoIcon mini sx={{ width: 42 }} />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
        </>
    );
}

ConnectionsDrawer.propTypes = {
    activeBusiness: PropTypes.any,
};
