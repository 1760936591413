import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import {
    useActionData,
    useLoaderData,
    useNavigate,
    useRevalidator,
    useRouteLoaderData,
    useSubmit,
} from 'react-router-dom';
import * as api from '../../../../api';
import * as businessProvider from '../../../../providers/businessProvider';
import LoadingButton from '../../../../components/LoadingButton/LoadingButton.jsx';
import { OrderDiscountTypes } from '../../../../constants/OrderDiscountTypes.jsx';
import { OrderStatuses } from '../../../../constants/OrderStatuses.jsx';
import { PaymentStatuses } from '../../../../constants/PaymentStatuses.jsx';
import OrderSummaryCard from '../../../cart/OrderSummaryCard.jsx';
import BrandOrderProductCard from './BrandOrderProductCard.jsx';
import EditLineItemsDialog from './EditLineItemsDialog.jsx';
import TrackingNumbers from './TrackingNumbers';
import OrderNote from '../../../../components/OrderNote/OrderNote.jsx';
import OrderNoteInput from '../../../../components/OrderNoteInput/OrderNoteInput.jsx';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import ShopifyInventoryMismatch from '../../../../components/ShopifyInventoryMismatch/ShopifyInventoryMismatch.jsx';
import CancelOrderDialog from '../../../retail/orders/CancelOrderDialog.jsx';
import { CircularProgress } from '@mui/material';
import { useError } from '../../../../components/Error/ErrorProvider.jsx';

export async function loader({ request, params }) {
    const { signal } = request;
    const url = new URL(request.url);
    const success = url.searchParams.get('success');
    const hasSuccessFlag = success === 'true';

    let order;
    if (hasSuccessFlag) {
        const retryAttempts = 8;
        order = await api.getOrderAfterPayment(params.orderId, retryAttempts, { signal });
    } else {
        order = await api.getOrderById(params.orderId, { signal });
    }

    const paymentStatus = [...(order?.payments || [])].pop()?.status || PaymentStatuses.OPEN;
    const defaultBusiness = await businessProvider.getBusiness();
    const businesses = defaultBusiness?.connectedBusiness || [];
    const retailBusinessData = [...(businesses || [])]?.filter((b) => b.businessId === order.retailBusinessId).pop();
    return {
        retailBusinessData,
        order,
        hasSuccessFlag,
        paymentStatus,
        businessesById: [defaultBusiness, ...(businesses || [])]?.reduce((acc, curr) => {
            return {
                ...acc,
                [curr.businessId]: curr,
            };
        }, {}),
    };
}

export async function action({ request, params }) {
    const payload = await request.json();
    const { intent } = payload;
    try {
        if (intent === 'takePayment') {
            const response = await api.payForOrder(params.orderId);
            window.location = response?.url;
        }
        if (intent === 'createNote') {
            const { note } = payload;
            return api.createOrderNote(note, { signal: request.signal });
        }
    } catch (error) {
        return { error };
    }
    return null;
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function BrandOrderDetails() {
    const navigate = useNavigate();
    const submit = useSubmit();
    const { business, thatchUser } = useRouteLoaderData('root') || {};
    const { revalidate } = useRevalidator();
    const actionData = useActionData();
    const { handleError } = useError();
    const { businessesById, order: initialData = {}, retailBusinessData, paymentStatus } = useLoaderData() || {};
    const [initialOrder, setInitialOrder] = React.useState(initialData);
    const [order, setOrder] = React.useState(JSON.parse(JSON.stringify(initialOrder ?? {})));

    const [isUpdatingStatus, setIsUpdatingStatus] = React.useState(false);
    const [openDecline, setOpenDecline] = React.useState(false);
    const [hasMadeChanges, setHasMadeChanges] = React.useState(false);
    const [showEditLineItemsDialog, setShowEditLineItemsDialog] = React.useState(false);
    const [lineItemsError, setLineItemsError] = React.useState();
    const isPaymentComplete = paymentStatus === PaymentStatuses.COMPLETE;
    const isPaymentPending = paymentStatus === PaymentStatuses.PENDING;
    const isPaymentFailed = paymentStatus === PaymentStatuses.FAILED;
    const isBusinessPayoutsEnabled = business?.settings?.paymentProviderSettings?.payoutsEnabled;
    const handleOpenDecline = () => setOpenDecline(true);
    const handleCloseDecline = () => setOpenDecline(false);
    const [showCancelOrderDialogue, setShowCancelOrderDialogue] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [snackbarSeverity, setSnackbarSeverity] = React.useState('info');

    const [approvalNote] = React.useState(order?.approvalNote || '');
    const [declineReason, setDeclineReason] = React.useState(order?.declineReason || '');

    const [newOrderNote, setNewOrderNote] = React.useState('');

    const { t } = useTranslation();
    const [inventoryWarningData, setInventoryWarningData] = useState(null);
    const updateOrderState = (updater) => {
        setOrder((prevState) => {
            const update = updater(prevState);
            const { discountAmount, discountType, orderItems = [], shippingCost } = update;
            const subTotal = orderItems.reduce((acc, curr) => acc + Number(curr.quantity) * Number(curr.price), 0);
            let discountTotal = 0;
            if (discountAmount && discountType) {
                discountTotal =
                    discountType === OrderDiscountTypes.PERCENT
                        ? Number((subTotal * Number(discountAmount)) / 100)
                        : discountAmount;
            }
            return {
                ...update,
                subTotal,
                total: Number(shippingCost) + subTotal - Number(discountTotal),
            };
        });
    };
    // Function to handle closing the snackbar
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    // Function to show the snackbar
    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    // Helper function to format date
    const formatDate = (dateString) => {
        if (!dateString) {
            return '';
        }
        return format(new Date(dateString), 'PPpp');
    };

    // Helper function to format address
    const formatAddress = (address) => {
        return (
            <>
                {address.name && <Typography>{address.name}</Typography>}
                {address.street1 && <Typography>{address.street1}</Typography>}
                {address.street2 && <Typography>{address.street2}</Typography>}
                {address.city && (
                    <Typography>
                        {address.city}, {address.state} {address.postalCode}
                    </Typography>
                )}
                {address.countryCode && <Typography>{address.countryCode}</Typography>}
                {address.emails &&
                    address.emails.map((email, index) => <Typography key={index}>{email.office}</Typography>)}
                {address.phones &&
                    address.phones.map((phone, index) => <Typography key={index}>{phone.office}</Typography>)}
            </>
        );
    };

    const isEditable = order.status === OrderStatuses.IN_REVIEW;

    // Handlers for new actions
    const handleUpdateTrackingNumbers = (trackingNumbers) => {
        updateOrderState((prevOrder) => ({
            ...prevOrder,
            trackingNumbers,
        }));
        setHasMadeChanges(true);
    };

    const handleDecline = () => {
        const reason = document.getElementById('decline-reason').value;
        updateOrderStatus('Declined', reason)
            .then(() => {
                setDeclineReason(reason);
                showSnackbar('Order declined successfully', 'success');
                return reason;
            })
            .catch((error) => {
                console.error('Error declining order:', error);
                showSnackbar('Error declining order', 'error');
            });
    };

    const handleCancelOrder = () => {
        // Logic to cancel the order
        setShowCancelOrderDialogue(true);
    };

    const handleMarkAsShipped = async () => {
        // Logic to mark the order as shipped
        await updateOrderStatus('Shipped');
    };

    const handleTakePayment = () => {
        submit({ intent: 'takePayment' }, { method: 'post', encType: 'application/json' });
    };

    const handleProductUpdate = (updatedProduct) => {
        const updatedItems = order.orderItems.map((item) => {
            if (item.id === updatedProduct.id) {
                return updatedProduct;
            }
            return item;
        });
        updateOrderState((prevOrder) => ({
            ...prevOrder,
            orderItems: updatedItems,
        }));
        setHasMadeChanges(true);
    };

    const handleDeleteProduct = (product) => {
        const filteredItems = order.orderItems.filter((item) => item.id !== product.id);

        updateOrderState((prevOrder) => ({
            ...prevOrder,
            orderItems: filteredItems,
        }));
        setHasMadeChanges(true);
    };

    const mapOrderToUpdatePayload = (order) => {
        const {
            businessId,
            discountAmount,
            discountType,
            id,
            retailBusinessId,
            shippingCost,
            status,
            subTotal,
            total,
            trackingNumbers,
        } = order;
        const orderItems = order.orderItems.map((item) => ({
            id: item.id,
            price: item.price,
            productVariantId: item.productVariantId,
            quantity: item.quantity,
        }));
        return {
            businessId,
            discountAmount,
            discountType,
            id,
            orderItems,
            retailBusinessId,
            shippingCost,
            status,
            subTotal,
            total,
            trackingNumbers,
        };
    };

    const saveChanges = async () => {
        const payload = mapOrderToUpdatePayload(order);
        try {
            const response = await api.updateOrder(order.id, payload);
            if (response.ok) {
                setInitialOrder(order);
                showSnackbar('Order updated successfully', 'success');
            } else {
                showSnackbar('Error updating order', 'error');
                console.error('Error updating order');
            }
        } catch (error) {
            console.error('Error updating order', error);
            showSnackbar('Error updating order', 'error');
        } finally {
            setHasMadeChanges(false);
        }
    };

    useEffect(() => {
        if (hasMadeChanges) {
            saveChanges();
        }
    }, [hasMadeChanges]);

    useEffect(() => {
        if (isPaymentPending) {
            revalidate();
        }
    }, [paymentStatus]);

    useEffect(() => {
        if (actionData?.error) {
            handleError(actionData?.error);
        }
    }, [actionData]);

    const updateOrderStatus = async (updatedStatus, note = '') => {
        // Construct the payload
        const payload = {
            businessId: order.businessId,
            status: updatedStatus,
        };
        setIsUpdatingStatus(true);
        // Add either approvalNote or declineReason based on the status
        if (updatedStatus === 'Approved') {
            if (note) {
                payload.approvalNote = note;
            }
            const shopifyVariantIds = order.orderItems.map((orderItem) => {
                return orderItem.productVariant.thirdPartyId;
            });
            if (!_.isEmpty(shopifyVariantIds)) {
                const inventoryMismatches = await checkInventoryMismatch(order.orderItems);
                if (!_.isEmpty(inventoryMismatches)) {
                    setIsUpdatingStatus(false);
                    return [
                        setInventoryWarningData({
                            orderData: {
                                orderId: order.id,
                                payload,
                                note,
                                status: updatedStatus,
                            },
                            inventories: inventoryMismatches,
                        }),
                    ];
                }
            }
        } else if (updatedStatus === 'Declined' && note) {
            payload.declineReason = note;
        }
        await sendUpdatedOrderStatus({ orderId: order.id, status: updatedStatus, payload, note });
    };

    const sendUpdatedOrderStatus = async ({ orderId, status, payload, note }) => {
        try {
            const response = await api.updateOrderStatus(orderId, payload);
            if (response.ok) {
                showSnackbar(`Order ${status.toLowerCase()} successfully`, 'success');
                didFinishUpdatingStatus(status, note);
            } else {
                if (status === 'Approved') {
                    showSnackbar(`Error approving order`, 'error');
                } else if (status === 'Declined') {
                    showSnackbar(`Error declining order`, 'error');
                } else if (status === 'Cancelled') {
                    showSnackbar(`Error cancelling order`, 'error');
                } else if (status === 'Shipped') {
                    showSnackbar(`Error marking order as shipped`, 'error');
                } else {
                    showSnackbar(`Error updating order`, 'error');
                }
            }
        } catch (error) {
            showSnackbar(`Exception while ${status.toLowerCase()} order`, 'error');
        } finally {
            setIsUpdatingStatus(false);
        }

        if (status === 'Declined') {
            handleCloseDecline();
        }
    };

    const handleCloseInventoryWarning = () => {
        setInventoryWarningData(null);
    };

    const proceedWithInventoryMismatch = async () => {
        const { orderData } = inventoryWarningData;
        setIsUpdatingStatus(true);
        setInventoryWarningData(null);
        await sendUpdatedOrderStatus({
            orderId: orderData.orderId,
            payload: orderData.payload,
            status: orderData.status,
            note: orderData.note,
        });
    };

    async function checkInventoryMismatch(orderItems) {
        const shopifyVariantIds = orderItems.map((orderItem) => orderItem.productVariant.thirdPartyId);
        const linkedShops = business?.shopifySessions;
        if (_.isEmpty(shopifyVariantIds) || _.isEmpty(linkedShops)) {
            return [];
        }

        const availableInventory = await api.fetchAvailableShopifyInventory({ shopifyVariantIds }, {});
        if (!_.isEmpty(availableInventory)) {
            const unavailableInventories = orderItems
                .map((orderItem) => {
                    const inventory = availableInventory.find(
                        (inv) => inv.variantId.toString() === orderItem.productVariant.thirdPartyId.toString()
                    );
                    if (inventory && orderItem.quantity > inventory.available) {
                        return {
                            orderItem,
                            mismatch: orderItem.quantity - inventory.available,
                        };
                    }
                    return null;
                })
                .filter((item) => item !== null);

            return unavailableInventories;
        }
        return [];
    }

    // Update current Order and Initial Order
    const didFinishUpdatingStatus = (status, note) => {
        if (status === 'Approved' && note) {
            order.approvalNote = note;
        } else if (status === 'Declined' && note) {
            order.declineReason = note;
        }
        order.status = status;
        setInitialOrder(order);
    };

    const handleOpenEditLineItemsDialog = () => {
        setLineItemsError(null);
        setShowEditLineItemsDialog(true);
    };

    const handleApplyEditLineItems = async (e, values) => {
        const { discountType, discountValue: discountAmount, shippingValue: shippingCost } = values;
        const payload = {
            ...mapOrderToUpdatePayload(order),
            discountAmount,
            discountType,
            shippingCost,
        };
        try {
            setLineItemsError(null);
            const result = await api.updateOrder(order.id, payload);

            if (result.okay === false) {
                throw new Error(result?.data?.message);
            }
            setShowEditLineItemsDialog(false);
            const { discountAmount, discountType, shippingCost, subTotal, total } = result?.data;
            setOrder((prev) => ({ ...prev, discountAmount, discountType, shippingCost, subTotal, total }));
        } catch (error) {
            setLineItemsError(error);
        }
    };

    const handleInputNoteOnChange = (e) => {
        setNewOrderNote(e.target.value);
    };

    const handleInputNoteOnSubmit = () => {
        if (!newOrderNote || !newOrderNote.trim().length) {
            return;
        }
        const note = {
            businessId: order?.businessId,
            content: newOrderNote,
            createdByBusinessId: thatchUser?.businessId,
            createdByUser: thatchUser?.id,
            orderId: order.id,
        };
        submit({ intent: 'createNote', note }, { method: 'post', encType: 'application/json' });
    };

    const handleApprove = () => {
        updateOrderStatus('Approved')
            .then(() => {
                return showSnackbar('Order approved successfully', 'success');
            })
            .catch(() => {
                showSnackbar('Error approving order', 'error');
            });
    };

    // map in business names
    const notes = initialData?.orderNotes?.map((n) => {
        return { ...n, createdByBusinessName: businessesById?.[n.createdByBusinessId]?.name };
    });

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4, mt: 8 }}>
            <Grid container spacing={4} justifyContent="center">
                {/* Main Content Card */}
                <Grid item xs={12} lg={8}>
                    <Card variant="outlined" sx={{ border: '1px solid #e0e0e0', boxShadow: 'none', marginBottom: 4 }}>
                        <CardContent>
                            <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                                    Order #{order?.id} - Purchase Order
                                </Typography>
                                {isPaymentComplete ? (
                                    <Typography
                                        variant="h7"
                                        gutterBottom
                                        sx={{ fontWeight: 'bold', textAlign: 'right', color: 'common.green' }}>
                                        {t('BrandOrderDetails.paidLabel')}{' '}
                                        {!order?.payments?.[0]?.updatedAt
                                            ? null
                                            : format(new Date(order?.payments?.[0]?.updatedAt), 'MM/dd/yyyy')}
                                    </Typography>
                                ) : null}
                                {isPaymentPending ? (
                                    <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
                                        <Typography variant="h7" sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                                            {t('BrandOrderDetails.paymentPendingLabel')}
                                        </Typography>
                                        <CircularProgress size={15} />
                                    </Stack>
                                ) : null}
                                {isPaymentFailed ? (
                                    <Typography
                                        variant="h7"
                                        gutterBottom
                                        sx={{ fontWeight: 'bold', textAlign: 'right', color: 'common.red' }}>
                                        {t('BrandOrderDetails.paymentFailedLabel')}{' '}
                                        {!order?.payments?.[0]?.updatedAt
                                            ? null
                                            : format(new Date(order?.payments?.[0]?.updatedAt), 'MM/dd/yyyy')}
                                    </Typography>
                                ) : null}
                                {isPaymentFailed ? (
                                    <Typography
                                        variant="h7"
                                        gutterBottom
                                        sx={{ fontWeight: 'bold', textAlign: 'right', color: 'common.red' }}>
                                        {t('BrandOrderDetails.paymentFailedLabel')}{' '}
                                        {!order?.payments?.[0]?.updatedAt
                                            ? null
                                            : format(new Date(order?.payments?.[0]?.updatedAt), 'MM/dd/yyyy')}
                                    </Typography>
                                ) : null}
                            </Box>
                            <Divider sx={{ my: 2 }} />
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2} justifyContent="space-around">
                                    <Grid item xs={12} md={5}>
                                        <Typography>Order Submitted: {formatDate(order?.createdAt)}</Typography>
                                        <Typography>Last Updated: {formatDate(order?.updatedAt)}</Typography>
                                        <Typography
                                            variant="h6"
                                            color="textSecondary"
                                            sx={{ marginTop: 4 }}
                                            gutterBottom>
                                            Ship to:
                                        </Typography>
                                        {order?.addresses &&
                                            order.addresses.map((address, index) => (
                                                <Box key={index} sx={{ mb: 2 }}>
                                                    {formatAddress(address)}
                                                </Box>
                                            ))}
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <Typography variant="h6" color="textSecondary" sx={{ mb: 2 }}>
                                            Status: {order.status}
                                        </Typography>
                                        <TrackingNumbers
                                            initialTrackingNumbers={order?.trackingNumbers}
                                            onTrackingNumbersUpdate={handleUpdateTrackingNumbers}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider sx={{ my: 2 }} />
                        </CardContent>
                    </Card>
                    <Grid item xs={12}>
                        <Stack direction="column" spacing={2}>
                            {/* Stack the cards vertically */}
                            {order?.orderItems?.map((item) => (
                                <BrandOrderProductCard
                                    key={item.id}
                                    product={item}
                                    isEditable={isEditable}
                                    onChange={handleProductUpdate}
                                    onDelete={handleDeleteProduct}
                                />
                            ))}
                        </Stack>
                    </Grid>
                </Grid>
                {/* Summary Card on the Right */}
                <Grid item xs={12} lg={4}>
                    <OrderSummaryCard
                        order={order}
                        business={retailBusinessData}
                        variant="BRAND_REVIEW"
                        ShippingComponent={
                            !isEditable ? null : (
                                <Link underline="none" component="button">
                                    {!order.shippingCost || Number(order?.shippingCost) === 0 ? (
                                        '—'
                                    ) : (
                                        <NumericFormat
                                            prefix="$"
                                            displayType="text"
                                            value={order.shippingCost}
                                            thousandSeparator
                                            decimalScale={2}
                                            fixedDecimalScale
                                        />
                                    )}
                                </Link>
                            )
                        }
                        DiscountComponent={
                            !isEditable ? null : (
                                <Link underline="none" component="button">
                                    {!Number(order.discountAmount) ? (
                                        '—'
                                    ) : (
                                        <NumericFormat
                                            prefix={
                                                order?.discountType === OrderDiscountTypes.DOLLARS ? '$' : undefined
                                            }
                                            suffix={
                                                order?.discountType === OrderDiscountTypes.PERCENT ? '%' : undefined
                                            }
                                            displayType="text"
                                            value={order?.discountAmount}
                                            thousandSeparator
                                            decimalScale={2}
                                            fixedDecimalScale
                                        />
                                    )}
                                </Link>
                            )
                        }
                        OrderSummaryCardActions={
                            <Stack direction="column" spacing={2} justifyContent="center">
                                {order.status === OrderStatuses.SHIPPED ? (
                                    <>
                                        <Button variant="contained" color="error" onClick={handleCancelOrder}>
                                            {t('OrderSummaryCard.buttons.cancelOrderLabel')}
                                        </Button>
                                        {!isPaymentComplete ? (
                                            <Button
                                                disabled={!isBusinessPayoutsEnabled}
                                                fullWidth
                                                variant="contained"
                                                size="small"
                                                color="success"
                                                name="intent"
                                                value="payNow"
                                                onClick={handleTakePayment}>
                                                {t('OrderSummaryCard.buttons.takePaymentLabel')}
                                            </Button>
                                        ) : null}
                                    </>
                                ) : order.status === OrderStatuses.APPROVED ? (
                                    <>
                                        <Button variant="contained" color="error" onClick={handleCancelOrder}>
                                            {t('OrderSummaryCard.buttons.cancelOrderLabel')}
                                        </Button>
                                        <Button variant="contained" onClick={handleMarkAsShipped}>
                                            {t('OrderSummaryCard.buttons.markAsShippedLabel')}
                                        </Button>
                                        {!isPaymentComplete ? (
                                            <Button
                                                disabled={!isBusinessPayoutsEnabled}
                                                fullWidth
                                                variant="contained"
                                                size="small"
                                                color="success"
                                                name="intent"
                                                value="payNow"
                                                onClick={handleTakePayment}>
                                                {t('OrderSummaryCard.buttons.takePaymentLabel')}
                                            </Button>
                                        ) : null}
                                    </>
                                ) : order.status === OrderStatuses.IN_REVIEW ? (
                                    <>
                                        <Button variant="contained" onClick={handleOpenEditLineItemsDialog}>
                                            {t('OrderSummaryCard.buttons.manageDetailsLabel')}
                                        </Button>
                                        <Button variant="contained" color="error" onClick={handleOpenDecline}>
                                            {t('OrderSummaryCard.buttons.declineOrderLabel')}
                                        </Button>
                                        <LoadingButton
                                            isLoading={isUpdatingStatus}
                                            variant="contained"
                                            color="success"
                                            onClick={handleApprove}>
                                            {t('OrderSummaryCard.buttons.approveSendInvoiceLabel')}
                                        </LoadingButton>
                                    </>
                                ) : null}
                            </Stack>
                        }
                    />
                    <Grid item sm={6} lg={12}>
                        <Box sx={{ display: 'flex', py: 2 }}>
                            <OrderNoteInput
                                onChange={handleInputNoteOnChange}
                                onSubmit={handleInputNoteOnSubmit}
                                fullWidth
                                multiline
                            />
                        </Box>
                        {notes?.map((note) => (
                            <OrderNote key={note.id} note={note} sx={{ py: 1 }} />
                        ))}
                        {approvalNote && (
                            <Card variant="outlined" sx={{ marginTop: 3 }}>
                                <CardContent>
                                    <Typography variant="subtitle1">Approval Note:</Typography>
                                    <Typography variant="body2">{approvalNote}</Typography>
                                </CardContent>
                            </Card>
                        )}

                        {declineReason && (
                            <Card variant="outlined" sx={{ marginTop: 3 }}>
                                <CardContent>
                                    <Typography variant="subtitle1">Decline Reason:</Typography>
                                    <Typography variant="body2">{declineReason}</Typography>
                                </CardContent>
                            </Card>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {/* Edit Line Items */}
            <EditLineItemsDialog
                discountValue={order.discountAmount}
                discountType={order.discountType}
                shippingValue={order.shippingCost}
                termsValue={order.terms}
                open={showEditLineItemsDialog}
                onConfirm={handleApplyEditLineItems}
                onClose={() => setShowEditLineItemsDialog(false)}
                error={lineItemsError}
            />
            {/* Decline Modal */}
            <Modal open={openDecline} onClose={handleCloseDecline} aria-labelledby="modal-decline-title">
                <Box sx={modalStyle}>
                    <Typography id="modal-decline-title" variant="h6" component="h2">
                        Decline Order
                    </Typography>
                    <TextField
                        margin="normal"
                        id="decline-reason"
                        label="Decline Reason"
                        type="text"
                        fullWidth
                        variant="outlined"
                    />
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleCloseDecline}>Cancel</Button>
                        <LoadingButton
                            isLoading={isUpdatingStatus}
                            onClick={handleDecline}
                            color="error"
                            variant="contained">
                            Decline!
                        </LoadingButton>
                    </Box>
                </Box>
            </Modal>
            <Modal open={inventoryWarningData !== null} aria-labelledby="modal-decline-title">
                <Box sx={modalStyle}>
                    <Stack gap={1}>
                        <Typography>
                            <strong>Warning:</strong> You do not have enough inventory in Shopify to fulfill this order.
                            Proceed anyway?
                        </Typography>
                        {inventoryWarningData?.inventories?.map((inventory, index) => (
                            <ShopifyInventoryMismatch key={index} inventoryData={inventory} />
                        ))}
                        <Stack direction="row" sx={{ mt: 2, justifyContent: 'space-between' }}>
                            <Button onClick={handleCloseInventoryWarning}>Cancel</Button>
                            <Button onClick={proceedWithInventoryMismatch} variant="contained">
                                Proceed
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Modal>
            <CancelOrderDialog
                orderId={order?.id}
                open={showCancelOrderDialogue}
                onClose={() => setShowCancelOrderDialogue(false)}
                onSuccess={() => navigate('.', { replace: true })}
                businessId={business?.businessId}
            />
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}
