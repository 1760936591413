import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGridPro } from '@mui/x-data-grid-pro';
import * as Sentry from '@sentry/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import _, { debounce } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useNavigate, useSearchParams } from 'react-router-dom';
import * as api from '../../../api';
import { EmptyTable } from '../../../components/EmptyTable/EmptyTable.jsx';
import SearchBar from '../../../components/SearchBar/SearchBar';
import SettingsAvatar from '../../../components/SettingsAvatar/SettingsAvatar.jsx';
import { createCurrencyColumn } from '../../../helpers/HelperFunctions.jsx';
import PageHeader from '../../../components/PageHeader/PageHeader.jsx';
import PlusCircleIcon from '../../../icons/PlusCircleIcon.jsx';

export async function loader({ request }) {
    try {
        const url = new URL(request.url);
        const searchString = url.searchParams.get('searchString') || '';
        const params = {
            query: {
                offset: 0,
                limit: 20,
            },
        };

        if (searchString) {
            params.query.searchString = searchString;
        }

        const orders = await api.getOrders(params);
        return { orders };
    } catch (error) {
        console.error('Failed to load orders:', error);
        Sentry.captureException(error);
        return { orders: { rows: [], hasMore: false } };
    }
}

export default function BrandOrders() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const flags = useFlags();
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);

    const [search, setParams] = useSearchParams();
    const [searchValue, setSearchValue] = useState(search.get('searchString'));
    const [hasMore, setHasMore] = useState(false);
    const offset = useRef(0);

    const data = useLoaderData();
    const initialOrders = data?.orders;
    const [error, setError] = useState(null);

    const queryProducts = useMemo(function () {
        return debounce((value) => {
            if (_.isString(value)) {
                if (_.isEmpty(value)) {
                    setParams();
                } else {
                    setParams({ searchString: value });
                }
            }
        }, 400);
    }, []);

    useEffect(() => {
        queryProducts(searchValue);
    }, [searchValue]);

    const handleSearchInputChange = (event) => {
        const value = event.target.value;
        setSearchValue(value);
    };

    useEffect(() => {
        if (initialOrders) {
            setOrders(initialOrders.rows.map(mapOrder));
            setHasMore(initialOrders.hasMore);
            offset.current = initialOrders.limit;
        }
    }, [initialOrders]);

    const mapOrder = (order) => {
        // Function to format date to MM/DD/YYYY
        const formatDate = (date) => {
            return new Date(date).toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
            });
        };

        // Function to sum the quantities of all order items
        const qty = order?.orderItems || [];
        const totalQuantity = qty.reduce((sum, item) => {
            return sum + parseInt(item.quantity, 10);
        }, 0);

        const mapped = {
            image: order.retailBusiness?.imageLinks[0] || '',
            id: order?.id || '',
            RetailerDealer: order?.retailBusiness?.name || '',
            orderType: order?.type || '',
            createdAt: formatDate(order.createdAt) || '',
            updatedAt: formatDate(order.updatedAt) || '',
            store: order?.addresses?.[0]?.city || '',
            quantity: totalQuantity,
            total: order?.total || '',
            terms: order?.terms || '',
            status: order?.status || '',
        };
        return mapped;
    };

    const loadMoreRows = async () => {
        if (!hasMore || loading) return;

        try {
            setLoading(true);
            const newData = await api.getOrders({
                query: {
                    offset: offset.current,
                    limit: 20,
                },
            });

            const newRows = newData.rows.map(mapOrder);
            setOrders((prevOrders) => [...prevOrders, ...newRows]);
            setHasMore(newData.hasMore);
            offset.current += newData.rows.length;
        } catch (error) {
            console.error('Failed to load more orders:', error);
            Sentry.captureException(error);
            setError('Failed to load more orders');
        } finally {
            setLoading(false);
        }
    };

    const handleOnRowsScrollEnd = () => {
        loadMoreRows();
    };

    const columns = [
        {
            field: 'image',
            headerName: 'Image',
            width: 130,
            renderCell: (params) => (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: '100%', height: '100%', borderRadius: '4px' }}>
                    <SettingsAvatar src={params.value ?? ''} type="Business" size={40} />
                </Box>
            ),
        },
        { field: 'id', headerName: 'Order Number', width: 150 },
        { field: 'RetailerDealer', headerName: 'Retailer/Dealer', width: 150 },
        { field: 'orderType', headerName: 'Order Type', width: 150 },
        { field: 'createdAt', headerName: 'Created At', width: 150 },
        { field: 'updatedAt', headerName: 'Last Updated', width: 150 },
        { field: 'store', headerName: 'Store', width: 150 },
        { field: 'quantity', headerName: 'Quantity', width: 150 },
        createCurrencyColumn({ field: 'total', headerName: 'Total', width: 150, currency: 'USD' }),
        { field: 'terms', headerName: 'Terms', width: 150 },
        { field: 'status', headerName: 'Status', width: 150 },
    ];

    const handleCellClick = (params, event) => {};

    const handleRowClick = (params) => {
        navigate(`/brand/orders/${params.id}`);
    };
    const theme = useTheme();
    const isSmBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Box>
            {flags.isWholeshopEnabled ? (
                <PageHeader
                    title={t('BrandOrders.title')}
                    actions={
                        flags.newOrder ? (
                            <Button
                                startIcon={<PlusCircleIcon sx={{ width: 24, height: 24 }} />}
                                variant="outlined"
                                color="secondary"
                                size={isSmBreakpoint ? 'sm' : 'xs'}
                                onClick={() => navigate('/brand/new-order')}>
                                {t('BrandOrders.addAnOrderButtonLabel')}
                            </Button>
                        ) : null
                    }
                    sx={{ mb: 3 }}
                />
            ) : null}
            {error && <p>Error: {error}</p>} {/* Display error message if there's an error */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                    mb: 2,
                }}>
                <SearchBar
                    onChange={handleSearchInputChange}
                    value={_.toString(searchValue)}
                    placeholder="Search orders"
                    sx={{ maxWidth: 360 }}
                />
                <Box>
                    {!flags.newOrder || flags.isWholeshopEnabled ? null : (
                        <Button
                            size="xs"
                            color="primary"
                            variant="contained"
                            onClick={() => navigate('/brand/new-order')}>
                            {t('BrandOrders.newOrderButtonLabel')}
                        </Button>
                    )}
                </Box>
            </Box>
            <DataGridPro
                columns={columns}
                rows={orders}
                loading={loading}
                onRowsScrollEnd={handleOnRowsScrollEnd}
                slots={{
                    loadingOverlay: LinearProgress,
                    noRowsOverlay: () => <EmptyTable message="No orders found" />,
                }}
                getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow')}
                onRowClick={(params) => {
                    handleRowClick(params);
                }}
                onCellClick={(params, event) => {
                    handleCellClick(params, event);
                    event.defaultMuiPrevented = true;
                }}
                autoHeight={false}
                sx={{
                    height: 1000,
                    '& .MuiDataGrid-row': {
                        cursor: 'pointer',
                    },
                    '& .MuiDataGrid-cell:focus': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                        outlineOffset: '0',
                        outline: 'none !important',
                    },
                }}
            />
        </Box>
    );
}
